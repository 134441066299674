<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="default">查詢</mdb-card-header>
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-select label="年月"
                                    v-model="year_month_select"
                                    @getValue="val=>{search.ym=val}"></mdb-select>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-btn class="mt-md-4"
                                 size="md"
                                 @click="getData()">
                            <i class="fa fa-search"></i>
                            &nbsp;查詢
                        </mdb-btn>
                    </mdb-col>
                    <mdb-col md="12"
                             class="p-0">
                        <hr class="m-0">
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-select label="分店"
                                    v-model="storeList"
                                    @getValue="val=>{search.id_store=val; }"></mdb-select>
                    </mdb-col>
                    <mdb-col md="12">
                        <table class="table table-bordered rwd-table-dispersion">
                            <thead>
                                <tr>
                                    <th colspan="2"
                                        style="width:33%">收入</th>
                                    <th colspan="2"
                                        style="width:33%">支出</th>
                                    <th colspan="2"
                                        style="width:33%">利潤</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowspan="7"
                                        style="width:20%">維修/銷售</td>
                                    <td rowspan="7"
                                        class="text-right">{{$numeral(data_process.price).format('0,0')}}</td>
                                    <td style="width:20%">材料成本</td>
                                    <td class="text-right">{{$numeral(data_process.cost).format('0,0')}}</td>
                                    <td style="width:20%"></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>採購成本</td>
                                    <td class="text-right">{{$numeral(data_process.oc_purchase).format('0,0')}}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>刷卡手續費</td>
                                    <td class="text-right">
                                        {{$numeral(data_process.total_swipe).format('0,0')}}
                                    </td>
                                    <td> </td>
                                    <td class="text-right">

                                    </td>
                                </tr>
                                <tr>
                                    <td>Line pay手續費</td>
                                    <td class="text-right">
                                        {{$numeral(data_process.total_linepay).format('0,0')}}
                                    </td>
                                    <td> </td>
                                    <td class="text-right">

                                    </td>
                                </tr>
                                <tr>
                                    <td>退貨報廢<small>(數量/總成本)</small></td>
                                    <td class="text-right">
                                        {{$numeral(data_process.scrapped_num).format('0,0')}}/{{$numeral(data_process.scrapped_cost).format('0,0')}}
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>新品不良<small>(數量/總成本)</small></td>
                                    <td class="text-right">
                                        {{$numeral(data_process.total_new_bad_num).format('0,0')}}/{{$numeral(data_process.total_new_bad_cost).format('0,0')}}
                                    </td>
                                    <td>毛利</td>
                                    <td class="text-right">
                                        {{
                                          $numeral(
                                            $math.chain(data_process.price)
                                              .subtract(data_process.cost)
                                              .subtract(data_process.scrapped_cost)
                                              .done()
                                          ).format('0,0')
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        營業費用<br>
                                        <small>人事+營業成本</small>
                                    </td>
                                    <td class="text-right">
                                        {{$numeral(data_process.oc_cost).format('0,0')}}
                                    </td>
                                    <td>毛利率</td>
                                    <td class="text-right">
                                        {{
                                        $numeral(
                                          $math.chain(data_process.price)
                                            .subtract(data_process.cost)
                                            .subtract(data_process.scrapped_cost)
                                            .subtract(data_process.total_swipe)
                                            .subtract(data_process.total_linepay)
                                            .subtract(data_process.total_new_bad_cost)
                                            .divide(data_process.price)
                                            .multiply(100)
                                            .done()
                                        ).format('0,0.0')
                                      }}%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        銷售總計
                                    </td>
                                    <td class="text-right">
                                        {{$numeral(data_process.price).format('0,0')}}
                                    </td>
                                    <td>
                                        支出總計
                                    </td>
                                    <td class="text-right">
                                        {{
                                        $numeral(
                                          $math.chain(data_process.cost)
                                            .add(data_process.scrapped_cost)
                                            .add(data_process.total_swipe)
                                            .add(data_process.total_linepay)
                                            .add(data_process.total_new_bad_cost)
                                            .add(data_process.oc_purchase)
                                            .add(data_process.oc_cost)
                                            .done()
                                        ).format('0,0')
                                      }}
                                    </td>
                                    <td>淨利
                                    </td>
                                    <td class="text-right">
                                        {{
                                        $numeral(
                                          $math.chain(data_process.price)
                                            .subtract(data_process.cost)
                                            .subtract(data_process.scrapped_cost)
                                            .subtract(data_process.total_swipe)
                                            .subtract(data_process.total_linepay)
                                            .subtract(data_process.total_new_bad_cost)
                                            .subtract(data_process.oc_purchase)
                                            .subtract(data_process.oc_cost)
                                            .done()
                                        ).format('0,0')
                                      }}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>{{ym==nowym?'當前總庫存':''}}</td>
                                    <td class="text-right">
                                        {{ym==nowym?$numeral(get_store_total_cost.stock).format('0,0'):''}}
                                    </td>
                                    <td>當月進貨</td>
                                    <td class="text-right">
                                        {{ $numeral(get_store_total_cost.purchase).format('0,0') }}
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </mdb-col>
                    <mdb-col md="12">
                        <table class="table table-striped rwd-table-dispersion"
                               v-show="false">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>產品名稱</th>
                                    <th>用量</th>
                                    <th>用量成本</th>
                                </tr>
                            </thead>
                            <tbody v-if="$moment().format('YYYY-MM')==search.ym">
                                <tr v-for="(p,pk) in data_process.data"
                                    :key="p.oi.id">
                                    <td>{{pk+1}}</td>
                                    <td>
                                        {{pList[p.oi.id_product].name}} <br>
                                        <small>{{pList[p.oi.id_product].code}}</small>
                                    </td>
                                    <td class="text-right">
                                        {{$numeral(p.use_num).format('0,0')}}
                                    </td>
                                    <td class="text-right">
                                        {{$numeral(p.use_cost).format('0,0')}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbBtn,
} from "mdbvue";
export default {
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbSelect,
    mdbRow,
    mdbCol,
    mdbBtn,
  },
  data() {
    return {
      search: {
        ym: "",
        id_store: "",
      },
      ym: "",
      nowym: "",
      oList: [],
      oiList: [],
      pList: {},
      ocList: [],
      storeList: [],
      stock_cost: [],
      purchase_cost: [],
      total_swipe: [],
      total_linepay: [],
      total_new_bad: [],
      year_month_select: [],
    };
  },
  mounted() {
    let vue = this,
      ylimit = vue.$moment().format("YYYY");
    vue.nowym = vue.$moment().format("YYYY-MM");
    vue.search.ym = vue.ym = vue.nowym;

    for (let y = 2020; y <= ylimit; y++) {
      vue.year_month_select.push({
        text: y + "年",
        value: null,
        disabled: true,
        optgroup: true,
      });
      for (let m = 12; m > 0; m--) {
        let str = `${ylimit}-${m < 10 ? "0" : ""}${m}`;
        if (str <= vue.nowym) {
          vue.year_month_select.push({
            text: `${ylimit}年${m < 10 ? "0" : ""}${m}月`,
            value: str,
            selected: vue.search.ym == str,
          });
        }
      }
    }
    vue.search.id_store = vue.$store.state.id_store;
    vue.getData();
  },
  computed: {
    data_process() {
      let vue = this,
        T = true,
        product_ids = [],
        output = {
          // 全部營收
          price: 0,
          // 全部成本
          cost: 0,
          // 報廢數量
          scrapped_num: 0,
          // 報廢成本
          scrapped_cost: 0,
          // 採購成本
          oc_purchase: 0,
          oc_cost: 0,
          data: [],
          // 刷卡手續費
          total_swipe: 0,
          // linepay手續費
          total_linepay: 0,
          // 新品不良(數量)
          total_new_bad_num: 0,
          // 新品不良(成本)
          total_new_bad_cost: 0,
        };
      vue.oiList.forEach((item) => {
        if (vue.search.id_store == "" || vue.search.id_store == item.id_store) {
          let p_index = product_ids.indexOf(item.id_product);
          if (p_index == -1) {
            p_index = product_ids.length;
            product_ids.push(item.id_product);
            output.data.push({
              oi: item,
              // 用量
              use_num: 0,
              // 用量成本
              use_cost: 0,
              // 銷貨退回數量
              return_num: 0,
              // 報廢數量
              scrapped_num: 0,
              // 報廢成本
              scrapped_cost: 0,
            });
          }
          if (item.remove == "0") {
            // 正常的
            output.data[p_index].use_num += parseInt(item.amount);
            output.data[p_index].use_cost += vue.$math.multiply(
              item.amount,
              item.cost
            );
            // 計入成本及營收
            output.price += parseInt(item.subtotal);
            output.cost += vue.$math.multiply(item.amount, item.cost);
          } else {
            // 銷貨退回的
            if (item.del_return == "0") {
              // 報廢
              output.data[p_index].scrapped_num += parseInt(item.amount);
              output.data[p_index].scrapped_cost += vue.$math.multiply(
                item.amount,
                item.cost
              );
              output.scrapped_num += parseInt(item.amount);
              output.scrapped_cost += vue.$math.multiply(
                item.amount,
                item.cost
              );
            } else {
              // 返回庫存
              output.data[p_index].return_num += parseInt(item.amount);
            }
          }
        }
      });
      // 處理營業成本
      vue.ocList.forEach((item) => {
        if (vue.search.id_store == "" || vue.search.id_store == item.id_store) {
          switch (item.type) {
            case "2":
              output.oc_purchase += parseInt(item.price);
              break;
            default:
              output.oc_cost += parseInt(item.price);
              break;
          }
        }
      });
      // 計算刷卡手續費
      if (T) {
        let swipe = 0;
        for (let s in vue.total_swipe) {
          if (vue.search.id_store == "" || vue.search.id_store == s) {
            swipe += parseInt(vue.total_swipe[s]);
          }
        }
        output.total_swipe = vue.$math.multiply(swipe, 0.0169);
      }
      // 計算linepay手續費
      if (T) {
        let linepay = 0;
        for (let s in vue.total_linepay) {
          if (vue.search.id_store == "" || vue.search.id_store == s) {
            linepay += parseInt(vue.total_linepay[s]);
          }
        }
        output.total_linepay = vue.$math.multiply(linepay, 0.0231);
      }
      // 計算新品報廢
      if (T) {
        let new_bad_num = 0,
          new_bad_cost = 0;
        for (let s in vue.total_new_bad) {
          if (vue.search.id_store == "" || vue.search.id_store == s) {
            new_bad_num += parseInt(vue.total_new_bad[s].num);
            new_bad_cost += parseInt(vue.total_new_bad[s].cost);
          }
        }
        output.total_new_bad_num = new_bad_num;
        output.total_new_bad_cost = new_bad_cost;
      }
      return output;
    },
    get_store_total_cost() {
      let vue = this,
        output = { stock: 0, purchase: 0 };
      vue.stock_cost.forEach((item) => {
        if (vue.search.id_store == "" || vue.search.id_store == item.id_store) {
          output.stock = vue.$math.add(output.stock, item.total_cost);
        }
      });
      vue.purchase_cost.forEach((item) => {
        if (vue.search.id_store == "" || vue.search.id_store == item.id_store) {
          output.purchase = vue.$math.add(output.purchase, item.total_cost);
        }
      });

      return {
        stock: vue.$math.round(output.stock),
        purchase: vue.$math.round(output.purchase),
      };
    },
  },
  methods: {
    getData() {
      let vue = this;
      vue.ym = vue.search.ym;
      vue.oList = [];
      vue.oiList = [];
      vue.pList = {};
      vue.ocList = [];
      vue.$store
        .dispatch("post_form", {
          payload: {
            url: "report/index",
            data: {
              ym: vue.search.ym,
            },
          },
        })
        .then((res) => {
          vue.oList = res.data.oList;
          vue.oiList = res.data.oiList;
          vue.pList = res.data.pList;
          vue.ocList = res.data.ocList;
          vue.storeList = res.data.storeList.map((item) => {
            item.value = item.id;
            item.text = item.name;
            item.selected = item.id == vue.search.id_store;
            return item;
          });
          vue.storeList.unshift({
            text: "全部",
            value: "",
            selected: vue.search.id_store == "",
          });
          vue.stock_cost = res.data.stock_cost;
          vue.purchase_cost = res.data.purchase_cost;
          vue.total_swipe = res.data.total_swipe;
          vue.total_linepay = res.data.total_linepay;
          vue.total_new_bad = res.data.total_new_bad;
        });
    },
  },
};
</script>